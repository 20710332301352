@import "../../style/variables.less";

@container-width: 1200px;
@primary-color: #0264E7;

@media screen and (max-width: 1600px) {
  @container-width: 1200px;
}

p {
  margin: 0;
}
.about-us {
  .nav-bg {
    position: relative;
    height: 480px;
    width: 100%;
    background-image: url(../../assets/about-us-bg.png);
    background-size: 100% 100%;
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .nav-text {
      position: relative;
      top: 25%;
      z-index: 10;
      width: 1200px;
      margin: auto;
      color: #fff;
      .nav-first-title {
        font-size: 30px;
        font-weight: bold;
      }
      .nav-second-title {
        line-height: 2;
        font-size: @font-size-sm;
        margin-top: 30px;
      }
    }
  }
  .about-tip {
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
    width: 1000px;
    .about-tip-li {
      flex: 1 1;
      text-align: center;
    }
    .about-tip-li > div {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 140px;
      height: 140px;
      padding: 10px;
      margin: auto;
      text-align: center;
      border-radius: 50%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #fff;
      }
      & > p:first-of-type {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        line-height: 1;
        margin-bottom: 0;
      }
      & > p:last-of-type {
        font-size: 15px;
        color: #fff;
      }
    }
    .circle-process1 {
      border: 10px solid #fff;
    }
  }
  .prefix-line {
    line-height: 1;
    margin-bottom: 20px;
    font-size: @font-size-lg;
    &::before {
      content: "";
      position: relative;
      top: 50%;
      display: inline-block;
      height: 3px;
      width: 122px;
      margin-right: 94px;
      background-color: @primary-color;
      vertical-align: middle;
    }
  }
  .prefix-line-white {
    text-align: left;
    color: #fff;
    &::before {
      background-color: #fff;
    }
  }
  .as-intro {
    position: relative;
    z-index: 1;
    width: @container-width;
    margin: 58px auto 100px;
    padding: 40px 20px;
    margin-top: -80px;
    box-shadow: 1px 1px 10px #d2d1d1;
    background: #fff;
    &::before {
      content: '';
      position: absolute;
      display: block;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(../../assets/as-input-bg.png);
      background-size: 100%;
      background-position: 60% 20%;
    }
    .as-intro-content {
      display: flex;
    }
    .as-intro-left {
      position: relative;
      flex: 1 1;
      align-self: center;
      text-align: center;
      background: #fff;
      & > img {
        display: inline-block;
        height: 220px;
      }
 
    }
    .as-intro-right {
      flex: 1 1;
      font-size: 22px;
      line-height: 1.5;
      .title {
        &::before {
          content: '';
          width: 8px;
          height: 40px;
          position: absolute;
          top: 5px;
          left: -25px;
          border-radius: 8px;
          background-image: linear-gradient(to bottom, #34b6fc,  #6dc2f0, transparent);
      }
    }
      &>p:nth-child(odd) {
        position: relative;
        margin-top: 10px;
        margin-bottom: 5px;
        font-weight: bold;
       
      }
      &>p:nth-child(even) {
        font-size: 20px;
        color: gray;
      }
      &>p:first-child {
        margin-top: 0px;
     
      }
    }
  }
  .as-function {
    position: relative;
    padding: 0px 0 0px;
    background-image: url('../../assets/as-function-bg.png');
    background-size:cover;
    background-position: 20%;
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  
    .as-function-container {
      position: relative;
      z-index: 10;
      width: @container-width;
      margin: auto;
      padding: 60px;
      border-radius: 6px;
    }

    .as-function-block {
      display: flex;
      justify-content: center;
      .funciton-block {
        position: relative;
        top: 0;
        width: 450px;
        height: 300px;
        margin-top: 20px;
        text-align: center;
        background: #fff;
        border-radius: 10px;
        margin-right: 60px;
        &:last-child {
          margin-right: 0;
        }
        & > img {
          position: relative;
          z-index: 3;
          height: 86px;
          margin-top: 30px;
        }
        .fb-title1 {
          position: relative;
          z-index: 3;
          margin-top: 10px;
          font-size: @font-size-sm;
          line-height: 46px;
        }
        .fb-title2 {
          position: relative;
          z-index: 3;
          margin-top:10px;
          font-size: @font-size-base;
          line-height: 30px;
          color: #565b61;
        }
        &:hover {
          top:  -10px;
          transition: top 0.1s;
        }
         
      }
      .funciton-block::after {
        content: "";
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }
  }
  .as-qualification {
    padding: 58px;
    width: @container-width;
    margin: auto;
    .swiper-container {
      display: flex;
      width: 1200px;
      margin: 20px auto;
      .swiper-slide {
        width: 500px;
        height: 1200px;
        text-align: center;
        img {
          height: 534px;
          margin: auto;
        }
      }
    }
  }
  .as-position {
    position: relative;
    padding: 58px 0;
    background-image: url('../../assets/as-operation-bg.png');
    background-size: 50%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .as-position-container {
      position: relative;
      z-index: 10;
      width:@container-width ;
      margin: auto;
      .as-position-block {
        .as-p-cell {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 504px;
          height: 84px;
          border: 1px solid #fff;
          font-size: @font-size-sm;
          color: #fff;
          &:hover {
            border-color: @primary-color;
            box-shadow: 1px 1px 10px @primary-color;
          }
        }
        .as-p-cell:nth-child(2n) {
          margin: 16px 66px;
        }
        
      }
    }
  }
  .about-swiper {
    display: flex;
    width: 1200px;
    margin: auto;
    .swiper-conent {
      position: relative;
      width: 477px;
      height: 277px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
  .as-contact {
    background-image: url('../../assets/as-input-bg.png');
    background-size: 100% 100%;;
    .as-contact-container {
      width: @container-width;
      margin: auto;
      padding: 58px 0;
     
      .contact-us {
        .contact-input {
          padding-left: 28px;
          margin-top: 20px !important;
          width: 532px;
          height: 62px;
          font-size: @font-size-base;
          border: 1px solid #475363;
          outline: none;
        }
        .contact-input:first-child {
          margin-right: 36px;
        }
        .contact-input-area {
          padding: 28px;
          width: 1100px;
          height: auto;
        }
      }
      .contact-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 684px;
        height: 72px;
        border: 1px solid @primary-color;
        margin: 20px auto 0;
        text-align: right;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          border-color: @primary-color;
          box-shadow: 1px 1px 10px @primary-color;
        }
        &:active {
          border-color: @primary-color;
          box-shadow: 1px 1px 10px @primary-color;
        }
      }
    }
   
  }
}


#certify {
	position: relative;
	margin: 0 auto
}

#certify .swiper-container {
	padding-bottom: 20px;
}

#certify  .swiper-slide {
  display: flex;
	width: 500px;
	height: 660px;
	background: #fff;
  box-shadow: 0 8px 30px #ddd;
  background-image: url('../../assets/qulification-bg.png');
  background-size: 100% 100%;
}
#certify  .swiper-slide img{
	display:block;
}
#certify  .swiper-slide p {
	line-height: 98px;
	padding-top: 0;
	text-align: center;
	color: #636363;
	font-size: 1.1em;
	margin: 0;
}

#certify .swiper-pagination {
	width: 100%;
	bottom: 20px;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 5px;
	border: 3px solid #fff;
	background-color: #d5d5d5;
	width: 10px;
	height: 10px;
	opacity: 1;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
	border: 3px solid #00aadc;
	background-color: #fff;
}

#certify .swiper-button-prev {
	left: -30px;
	width: 45px;
	height: 45px;
	background: url(../../assets/wm_button_icon.png) no-repeat;
	background-position: 0 0;
	background-size: 100%;
}

#certify .swiper-button-prev:hover {
	background-position: 0 -46px;
	background-size: 100%
}

#certify .swiper-button-next {
	right: -30px;
	width: 45px;
	height: 45px;
	background: url(../../assets/wm_button_icon.png) no-repeat;
	background-position: 0 -93px;
	background-size: 100%;
}

#certify .swiper-button-next:hover {
	background-position: 0 -139px;
	background-size: 100%
}