/* 文字颜色 */
/* 文字尺寸 */
/* 尺寸 */
body {
  margin: 0;
  font-family: "PingFangTC-Regular", "微软雅黑", PingFangSC-Regular, helvetica neue, hiragino sans gb, microsoft yahei, tahoma, microsoft yahei ui, simsun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  line-height: 1.5;
}
li {
  list-style-type: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.first-title {
  font-size: 25px;
  font-weight: bold;
  color: #2e2e2e;
  line-height: 1.5;
}
.second-title {
  font-size: 16px;
  color: #2e2e2e;
}
.third-title {
  font-size: 16px;
  color: #2e2e2e;
}
.se-lt-tx {
  display: flex;
  padding: 50px 0;
}
.se-lt-tx > div {
  flex: 1 1;
}
.se-lt-tx .left {
  align-self: center;
}
.se-lt-tx .left .title {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
}
.se-lt-tx .left .content {
  font-size: 16px;
}
.se-lt-tx .right {
  text-align: right;
}
.se-lt-img {
  display: flex;
  padding: 50px 0;
}
.se-lt-img > div {
  flex: 1 1;
}
.se-lt-img .right {
  align-self: center;
}
.se-lt-img .right .title {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
}
.se-lt-img .right .content {
  font-size: 16px;
}
.se-lt-img .left {
  text-align: left;
}
.section-title {
  position: relative;
  z-index: 1;
  margin-top: 82px;
  margin-bottom: 63px;
  text-align: center;
}
.section-title .title {
  position: relative;
  display: inline-block;
  width: 300px;
  padding-bottom: 13px;
  font-family: Adobe Heiti Std;
  border-bottom: 1px solid #dddddd;
}
.section-title .title p:first-child {
  font-weight: bold;
  font-size: 29px;
  color: #000000;
}
.section-title .title p:last-child {
  margin-top: 3px;
  font-size: 14px;
  color: #727272;
}
.section-title .title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  margin: auto;
  width: 80px;
  height: 4px;
  background: #f8b600;
}
.section-title .desc {
  margin-top: 40px;
  font-size: 20px;
  color: #727272;
}
