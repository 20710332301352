@import "../style/variables.less";
.app-container {
  min-width: 1263px;
}
.app-header-hidden {
  display: none;
}
.app-header {
  position: fixed;
  z-index: 3000;
  top: 0;
  width: 100%;
  height: 66px;
  background-color: rgba(0, 0, 0, 0.95);
  min-width: 1200px;
  .menu-container {
    height: 100%;
    width: 700px;
 
    margin: auto;
  }
  .menu-right {
    position: absolute;
    right: 20px;
    top: 0;
    .menu-apply-btn {
      background: @primary-color;
      border-radius: 2px;
      color: #fff;
      height: 36px;
      margin-right: 20px;
      line-height: 36px;
      width: 88px;
      font-size: @font-size-xs;
      text-align: center;
      display: inline-block;
      cursor: pointer;
    }
    .menu-right-item {
      position: relative;
      display: inline-block;
      font-size: @font-size-xs;
      font-weight: 500;
      margin: 0 10px;
      vertical-align: middle;
      height: 100%;
      line-height: 63px;
      box-sizing: border-box;
      cursor: pointer;
      & > img {
        vertical-align: middle;
      }
    }
    .menu-right-item-line::before {
      content: "";
      position: relative;
      left: -10px;
      display: inline-block;
      height: 12px;
      width: 1px;
      vertical-align: middle;
      background-color: #fff;
    }
    .dropbox {
      display: none;
      right: -13px;
      top: 54px;
      position: absolute;
      margin: 12px 0 0 48px;
      background-color: #fff;
      box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.31);
      border: 1px solid #ccc;
      border: 0 transparent;
      line-height: normal;
    }
    .dropbox-arrow {
      position: absolute;
      width: 0;
      height: 0;
      top: -29px;
      left: 102px;
      border-width: 15px;
      border-color: transparent transparent #fff;
      border-style: dashed dashed solid;
      overflow: hidden;
    }
    .dropbox-img {
      width: 119px;
      height: 119px;
    }
    .dropbox-content {
      padding: 10px;
    }
    .dropbox-p {
      margin: 0;
      text-align: center;
      font-size: @font-size-xs;
    }
  }
  .menu-item {
    position: relative;
    display: inline-block;
    font-size: @font-size-base;
    font-weight: 500;
    margin: 0 20px;
    vertical-align: middle;
    height: 100%;
    line-height: 63px;
    box-sizing: border-box;
    &:hover {
      border-bottom: 2px solid #00a4ff;
    }
  }
  .menu-link {
    display: inline-block;
    width: 100%;
    height: 100%;
    letter-spacing: 1px;
    color: #fff;
  }
  .menu-link-active {
    color: #00a4ff;
  }
  .menu-list {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 120px;
    z-index: -10;
    top: 65px;
    left: -25px;
    right: auto;
    padding: 5px 10px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    & > span {
      color: #fff;
    }
  }
  .hovered {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.8s;
  }
  .active {
    position: relative;
    color: #00a4ff;
    border-bottom: 2px solid #00a4ff;
  }
  .logo {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    margin-left: 30px;
    width: 180px;
    float: left;
  }
}
.app-header-visible {
  opacity: 0;
  margin-bottom: 66px;
  transition: opacity 1s ease-out;
  &:hover {
    opacity: 1;
    transition: opacity 1s ease-out;
  }
}

.app-content {
  margin-top: 66px;
  min-height: 100vh;
  min-width: @min-width;
}

.app-content-hover {
  min-height: 100vh;
  min-width: @min-width;
}

.hidden {
  display: none;
}

.app-footer {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.95);
  .footer-content {
    display: flex;
    width: 1100px;
    padding: 60px 0;
    margin: auto;
    color: #fff;
    .f-icon {
      width: 20px;
      margin-right: 10px;
      vertical-align: bottom;
    }
    .f-mailbox {
      width: 17px;
    }
    .f-location {
      margin-left: -1px;
    }
    .fc-text-sm {
      margin-top: 10px;
      font-size: 14px;
    }
 
    .fc-1 {
      margin-right: 60px;
    }
    .fc-1-block {
      display: flex;
      & > div {
        margin-right: 20px;
      }
    }
    .fc-interval {
      margin-top: 20px;
    }
    .qrcode-block {
      display: flex;
      margin-left: 20px;
    }
    .fc-qrcode {
      align-self: center;
      text-align: center;
      margin-right: 20px;
      img {
        width: 80px;
      }
      .qrcode-public-platform {
        padding: 0 20px;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
        color: #7e858c;
      }
    }
    .download-center {
      a {
        color: #fff;
        text-decoration: none;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .copyright {
    height: 40px;
    width: 100%;
    border-top: 1px solid rgba(66, 75, 84, 0.56);
    color: #fff;
    font-size: 13px;
    .copyright-info {
      text-align: center;
      width: 100%;
      padding-top: 10px;
      color: #7e858c;
      a {
        text-decoration: none;
        color: #7e858c;
      }
    }
  }
}

.company-apply {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(21, 27, 31, 0.7);
  z-index: 9999999;
  .apply-warp {
    background-image: linear-gradient(180deg, #b9d7e4 1%, #ffffff 33%);
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 450px;
    transform: translate(-50%, -50%);
    padding: 40px;
    box-sizing: border-box;
    .title {
      font-family: "PingFangTC-Semibold";
      font-size: 32px;
      color: @primary-color;
      text-align: center;
      line-height: 45px;
    }
    .desc {
      font-family: "PingFangTC-Regular";
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      line-height: 20px;
      padding: 16px 0 40px;
    }
    .apply-wrap-close {
      width: 24px;
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;
    }
    .form {
      .input {
        input {
          opacity: 0.9;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          height: 48px;
          width: 100%;
          line-height: 48px;
          outline: none;
          padding: 0 16px;
          box-sizing: border-box;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 12px;
          font-family: "PingFangTC-Regular";
        }
       
        .pc-card {
          position: relative;
          .btns {
            cursor: pointer;
            position: absolute;
            right: 19px;
            top: 1px;
            width: 100px;
            height: 46px;
            line-height: 46px;
            font-family: "PingFangTC-Regular";
            font-size: 14px;
            color: @primary-color;
            text-align: right;
          }
          .btns-disabled {
            color: grey;
            cursor: default;
          }
        }
        .submit {
          border: none;
          text-align: center;
          width: 100%;
          height: 54px;
          line-height: 54px;
          outline: none;
          background: @primary-color;
          border-radius: 8px;
          font-family: "PingFangTC-Semibold";
          font-size: 16px;
          color: #ffffff;
          margin: 18px 0 10px;
          cursor: pointer;
        }
      }
    
    }
  }
}
