.loading-wrapper {
  background: #fff;
  opacity: .35;
  cursor: no-drop !important;
  .loading-icon {
    display: inline-block;
    color: inherit;
    margin-right: 10px;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-animation: loadingCircle 1s linear infinite;
            animation: loadingCircle 1s linear infinite;
  }
}

.loading-icon  {
  display: none;
}

@-webkit-keyframes loadingCircle {
  to {
    transform: rotate(1turn);
  }
}

@keyframes loadingCircle {
  to {
    transform: rotate(1turn);
  }
}
