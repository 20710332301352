@import '../../style/variables.less';
.solution-mall {
  min-width: 1400px;
  .nav-bg {
    position: relative;
    display: flex;
    height: 70vh;
    width: 100%;
    background-image: url(../../assets/mall-nav.png);
    background-size: 100% 100%;
  }

  .sm-challenge {
    position: relative;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    margin: auto 0;
    padding: 40px 0;
    p {
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 36px;
      line-height: 67px;
      color: #fff;
      text-align: center;
    }
  }
  .sm-value {
    width: 1200px;
    margin: 40px auto;
    .sm-cv-ti {
      margin-bottom: 20px;
      color: #2e2e2e;
      font-size: @font-size-lsm;
      font-weight: bold;
      line-height: 1;
    }
    .sm-cv-tx {
      margin-bottom: 20px;
      color: #2e2e2e;
      font-size: @font-size-base;
    }
    .sm-cv-img {
      text-align: center;
      img {
        display: inline-block;
        width: 55%;
      }
    }
  }
  .sm-tx-img {
    margin: 30px auto 0;
    background: #eeeeee;
    & > div:first-child {
      padding-top: 40px;
    }
    & > div {
      width: 1200px;
      margin: auto;
    }
    .se-lt-img {
      padding-top: 20px;
    }
    img {
      width: 400px;
    }
    .sm-img-1 {
      width: 450px;
    }
  }
  .sm-data {
    background: #fff;
  }
  .sm-app {
    width: 1200px;
    margin: auto;
    padding: 40px 0;
    .sm-cl-ti {
      p {
        color: #2e2e2e;
        span {
          display: inline-block;
          padding-bottom: 10px;
          font-size: @font-size-lsm;
          border-bottom: 2px solid #2e2e2e;
        }
      }
    }
    .sm-app-li {
      display: flex;

      & > div:first-child {
        flex: 1 1;
      }
      & > div:last-child {
        flex: 2 1;
      }
      .sm-app-lt {
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100px;
          height: 100px;
          border: 5px solid #6ea9fa;
          border-radius: 50%;
          span {
            line-height: 2;
          }
        }
      }
      .sm-app-rt {
        margin-bottom: 20px;
        padding-top: 10px;
        background: #dbe9fe;
        border-radius: 6px;
        min-height: 120px;
        .title {
          text-align: center;
          font-size: 20px;
          margin-bottom: 10px;
        }
        .lct {
          height: 100%;
          align-items: center;
        }
        .content {
          display: flex;
          & > div {
            flex: 1 1;
            text-align: center;
            line-height: 2;
            font-size: 18px;
            img {
              height: 65px;
            }
          }
        }
      }
    }
  }
}
