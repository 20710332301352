@import '../../style/variables.less';
.zk {
  .nav-bg {
    position: relative;
    height: 70vh;
    width: 100%;
    margin-bottom: 40px;
    min-width: 1200px;
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: url(../../assets/zk-banner.png);
      background-size: cover;
      background-position: 20%;
    }
    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .tp-txt {
    position: absolute;
    width: 1200px;
    bottom: 35%;
    right: 10%;
    text-align: right;
    margin: auto;
    color: #fff;
    & > p:nth-of-type(1){
      font-size: 25px;
      font-weight: bold;
      line-height: 2;
    }
    & > p:nth-of-type(2),
    p:nth-of-type(3) {
      font-size: 18px;
    }
  }
  .bm-btn {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    display: flex;
    width: 1200px;
    justify-content: center;
    margin: 120px auto 0;
    div {
      flex: 1 1;
      text-align: center;
    }
    span {
      display: inline-block;
      padding: 5px 10px;
      border-radius: 6px;
      border: 1px solid #fff;
      &:hover {
        border-color: #0081ff;
        transform: scale(1.2);
        transition: transform 0.2s;
      }
    }
  }
  .btn {
    border: 1px solid;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 16px;
   
    padding: 10px 20px;
 
    color: #4cc9f0;
    border: 1px solid #fff;
    box-shadow: 1px 1px 10px #fff;
  }


  .active,.btn:hover {
    font-weight: bold;
    color: white;
    border: 0;
  }

  .active,.one:hover {
    background-color: #4cc9f0;
    box-shadow: 10px 10px 99px 6px rgba(76, 201, 240, 1);
  }
  .zk-se-li {
    position: relative;
    padding: 20px 0;
    .zk-container {
      display: flex;
      width: 1200px;
      margin: auto;
      justify-content: flex-start;
      & > div {
        flex: 1 1;
      }
    }
    & > .title {
      width: 1200px;
      margin: auto;
      &>span {
        display: inline-block;
        padding-bottom: 10px;
        margin-bottom:20px;
        font-size: @font-size-lsm;
      }
    }
    .content {
      margin-top: 40px;
    }
    img {
      width: 400px;
    }
    .zk-li-img-1 {
      width: 560px;
    }
    .right {
      text-align: right;
      .integral {
        text-align: right;
      }
      .img {
        text-align: right;
      }
    }
    .integral {
      .container {
        width: 400px;
        margin: auto;
      }
      .ct-b-ct {
        display: inline-block;
        position: relative;
        text-align: left;
        & > div {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0px 40px 40px;
          width: 80px;
          height: 80px;
          background: rgba(68, 138, 202, 0.47);
          border: 1px solid #ffffff;
          border-radius: 50%;
          img {
            width: 50px;
            height: 50px;
          }
          & > p {
            position: absolute;
            bottom: 5px;
            width: 100%;
            font-size: 14px;
            text-align: center;
            color: #2e2e2e;
          }
        }
      }
    }
   
  }
  .zk-bt {
    position: relative;
    display: flex;
    width: 100%;
    height: 300px;
    margin: 0px auto 0px;
    padding: 40px 0;
    background: url(../../assets/zk-bt-img.png);
    background-size: 100% 100%;
    & > div {
      position: absolute;
      right: 200px;
      top: 20px;
      color: #fff;
      & > p:nth-of-type(1) {
        font-size: 30px;
      }
      & > p:nth-of-type(2) {
        font-size: 20px;
        line-height: 2;
      }
      & > p:nth-of-type(3) {
        span {
          display: inline-block;
          padding: 5px 10px;
          margin-right: 20px;
          border-radius: 6px;
          border: 1px solid #fff;
        }
      }
    }
  }
  .zk-se-li {
    position: relative;
    padding: 20px 0;
    .zk-container {
      display: flex;
      width: 1200px;
      margin: auto;
      justify-content: flex-start;
      & > div {
        flex: 1 1;
      }
    }
    & > .title {
      width: 1200px;
      margin: auto;
      &>span {
        display: inline-block;
        padding-bottom: 10px;
        margin-bottom:20px;
        font-size: @font-size-lsm;
      }
    }
    .content {
      margin-top: 40px;
    }
    img {
      width: 400px;
    }
    .zk-li-img-1 {
      position: relative;
      top: 90px;
      width: 560px;
    }
    .zk-li-img-2 {
      width: 330px;
    }
    .right {
      text-align: right;
      .integral {
        text-align: right;
      }
      .img {
        text-align: right;
      }
    }
    .integral {
      .ct-b-ct {
        display: inline-block;
        position: relative;
        text-align: left;
        margin: 0 20px;
        & > div {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0px 40px 40px;
          width: 80px;
          height: 80px;
          background: rgba(68, 138, 202, 0.47);
          border: 1px solid #ffffff;
          border-radius: 50%;
          img {
            width: 50px;
            height: 50px;
          }
          & > p {
            position: absolute;
            bottom: 10px;
            width: 100%;
            font-size: 14px;
            text-align: center;
            color: #2e2e2e;
            white-space: nowrap;
          }
        }
      }
    }
    &:nth-child(odd) {
      .title {
        margin-bottom: 20px;
      }
    }
  }
}
