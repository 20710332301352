.spin {
  position: relative;
 
}
.spin-hidden {
  display: none !important;
}
.spin-spininng {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  &::before {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .2;
  }
}

.spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s linear infinite;
          animation: antRotate 1.2s linear infinite;
}

.spin-dot-item {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  background-color: #1890ff;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s linear infinite alternate;
          animation: antSpinMove 1s linear infinite alternate;
}

.spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 15px;
  text-shadow: 0 1px 2px #000;
  font-size:16px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}

@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}