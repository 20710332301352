.partner {
  .nav-bg {
    display: flex;
    position: relative;
    height: 480px;
    width: 100%;
    background-image: url(../../assets/product-introduce-bg.png);
    background-size: 100% 100%;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color:rgba(0,0,0,0.8);
    }
    .circle {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }
  .about-tip {
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
    width: 1000px;
    .about-tip-li {
      flex: 1 1;
      text-align: center;
    }
    .about-tip-li > div {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 174px;
      height: 174px;
      margin: auto;
      text-align: center;
      border-radius: 50%;
      border-color: #7f8388;
      .circle-process {
        position: absolute;
        left: 0;
        top: 0;
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/yuan.png);
        background-size: 100% 100%;
      }
      & > p:first-of-type {
        position: relative;
        font-size: 35px;
        font-weight: bold;
        color: #fff;
        line-height: 1;
        margin-bottom: 10px;
        font-family: "Impact";
        font-weight: bold;
      }
      & > p:last-of-type {
        font-size: 15px;
        color: #fff;
      }
    }
    .circle-process1 {
      border: 10px solid #fff;
    }
  }
  .title {
    margin: 60px 0;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }
  .section-grey {
    background: #EEEEEE;
  }
  .section-li {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    background: #EEEEEE;
    & > div {
      flex: 1 1;
      align-self: center;
    }
    .left {
      .l-title {
        margin-bottom: 20px;
        font-size: 25px;
        font-weight: bold;
      }
      .l-content {
      }
    }
    .right {
      text-align: right;
      width: 435px;
      height: 242px;
      img {
        width: 435px;
        height: 242px;
      }
    }
    .hoverEffe {
      right:0;
    }
  
  }
}

.section-li-2 {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  & > div {
    flex: 1 1;
    align-self: center;
  }
  .right {
    .r-title {
      margin-bottom: 20px;
      font-size: 25px;
      font-weight: bold;
    }
  }
  .left {
    text-align: left;
    width: 435px;
    height: 242px;
    img {
      background: #085c8f;
      width: 435px;
      height: 242px;
    }
  }
}


  .hoverEffe {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 435px;
    height: 242px;
    z-index: 1;
    background: #ff7f00;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    text-shadow: 5px 5px 5px #f72d2d;
    opacity: 0;
    transition: opacity .8s;
    &:hover {
      opacity: 1;
    }
  }
