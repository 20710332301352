@import '../../style/variables.less';

.product-introduce {
  .nav-bg {
    height: 70vh;
    width: 100%;
    background-image: url(../../assets/pi-banner.png);
    background-size: 100% 100%;
    overflow: hidden;
    .nb-container {
      position: relative;
      top: 50%;
      width: 1200px;
      margin: auto;
      transform: translateY(-50%);

      .nb-text-1 {
        position: relative;
        width: 405px;
        padding-bottom: 9px;
        font-size: 24px;
        color: #fff;
        border-bottom: 1px solid #a9a9a9;
        &::before {
          content: '';
          position: absolute;
          bottom: -2.5px;
          display: inline-block;
          width: 114px;
          height: 5px;
          background: #f8b600;
        }
      }
      .nb-text-2 {
        & > p:first-child {
          margin-top: 26px;
          font-size: 40px;
          color: #fff;
        }
        & > p:last-child {
          margin-top: 19px;
          font-size: 20px;
          color: #ffffff;
        }
      }
      .nb-btn {
        display: flex;
        margin-top: 40px;
        width: 360px;
        height: 54px;
        border: 1px solid #f8b600;
        a {
          text-decoration: none;
          color: #fff;
          font-size: 20px;
        }
        & > div {
          width: 50%;
          line-height: 54px;
          text-align: center;
          cursor: pointer;
        }
        & > div:first-child {
          color: #fff;
          background: #f8b600;
        }
        & > div:last-child {
          color: #f8b600;
          span {
            vertical-align: top;
            font-size: 20px;
            margin-left: 0px;
          }
        }
      }
    }
  }
  .zk-se-first {
    display: flex;
    justify-content: space-around;
    width: 1200px;
    margin: 122px auto;
    .img {
      text-align: center;
      img {
        display: inline-block;
        height: 57px;
      }
    }
    p {
      text-align: center;
    }
    p:nth-of-type(1) {
      margin-top: 34px;
      font-weight: bold;
      font-size: 20px;
      font-family: SJcujihei;
      color: #000000;
      line-height: 27px;
    }
    p:nth-of-type(2) {
      margin-top: 22px;
      font-size: 14px;
      font-family: SJcujihei;
      font-weight: 400;
      color: #5f5f5f;
      line-height: 22px;
    }
  }
  .zk-se-second {
    padding: 44px 0;
    background-color: #1b1c28;
    .container {
      width: 882px;
      margin: auto;
    }
    .title {
      margin-bottom: 10px;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 29px;
    }
    .block {
      display: flex;
      justify-content: space-between;
      .block-item {
        margin-top: 38px;
        width: 241px;
        height: 301px;
        padding: 30px;
        background: #fff;
        box-sizing: border-box;
        .img {
          margin-top: 30px;
          text-align: center;
          img {
            width: 52px;
          }
        }
        p{
          text-align: center;
        }
        p:nth-of-type(1) {
          margin-top: 16px;
          font-size: 20px;
          color: #333333;
        }
        p:nth-of-type(2) {
          margin-top: 16px;
          font-size: 14px;
          color: #636363;
        }
      }
    }
  }
  .pi-ads {
    width: 1200px;
    margin: 44px auto;
    .first-title {
      font-size: @font-size-lsm;
      font-weight: bold;
      color: #2e2e2e;
      line-height: 1.5;
    }
    .second-title {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: @font-size-base;
      color: #2e2e2e;
    }
    img {
      width: 100%;
      height: 500px;
    }
  }
  .pi-aot {
    .container {
      width: 1200px;
      margin: 44px auto;
    }
    img {
      width: 454px;
      height: 300px;
    }
  }
  .pi-content {
    width: 1200px;
    margin: 44px auto;
    .pi-ct-title {
      span {
        padding-bottom: 10px;
        border-bottom: 2px solid #d8d8d8;
      }
    }
    .pi-ct-li {
      display: flex;
      margin-top: 60px;
      & > div {
        flex: 1 1;
      }
      & > .ct-li-text {
        & > p:first-of-type {
          font-size: 20px;
          text-align: center;
        }
        & > div:last-of-type {
          line-height: 1.5;
          color: #1f1f1f;
          & > p {
            margin-top: 20px;
          }
        }
      }
      & > .ct-li-top-img {
        text-align: center;
        img {
          height: 282px;
        }
      }

      & > .ct-li-bottom-img {
        text-align: center;
        & > img {
          width: 448px;
        }
        .ct-b-li {
          display: flex;
          margin-bottom: 50px;
          & > div {
            flex: 1 1;
          }
        }
        .ct-b-ct {
          position: relative;
          text-align: left;
          & > div {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            img {
              width: 50px;
              height: 50px;
            }
            & > p {
              position: absolute;
              bottom: -40px;
              width: 100%;
              font-size: 14px;
              text-align: center;
              color: #2e2e2e;
            }
          }
        }
      }
    }
  }
  .li-gray {
    background-color: #eeeeee;
  }
}
