.alg-exper {
  overflow: hidden;
  .nav-bg {
    position: relative;
    display: flex;
    img {
      width: 100%;
    }
    .tp-txt {
      position: absolute;
      width: 1200px;
      bottom: 45%;
      left: 10%;
      text-align: left;
      margin: auto;
      color: #fff;
      & > p:nth-of-type(1){
        margin-bottom: 10px;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.5;
      }
      & > p:nth-of-type(2){
        font-size: 18px;
        a {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
  .nav-menu {
    width: 1200px;
    margin: auto;
    min-height: 105px;
    text-align: center;
    .nav-item:first-child {
      margin-top: 30px;
    }
    .nav-item {
      display: inline-block;
      line-height: 60px;
      font-size: 18px;
      color: #424b54;
      margin: 0 50px 20px 0;
      cursor: pointer;
      border: 1px solid transparent;
      background-color: #ebeff5;
      .nav-item-link {
        color: #424b54;
        line-height: 43px;
        padding: 5px 30px;
      }
    }
    .active {
      border: 1px solid #3bf;
      background-color: rgba(51, 187, 255, 0.07);
      .nav-item-link {
        color: #00a4ff;
        font-weight: 600;
      }
    }
  }

  .experience-container {
    position: relative;
    padding: 70px 0;
    background-color: #f5f7fa;

    .experience-container-info {
      display: flex;
      position: relative;
      font-size: 0;
      background: transparent;
      width: 1300px;
      margin: 0 auto 20px;
    }
    .img-result {
      height: 400px;
      background-color: #fff;
      margin: auto;
      display: inline-block;
      font-size: 16px;
      vertical-align: top;
      width: 400px;
      position: relative;
      cursor: pointer;
      box-shadow: 0 5px 15px 0 rgba(47, 99, 150, 0.1);
    }
    .right-container {
      width: 840px;
      padding-left: 40px;
      display: inline-block;
    }
    .left-retrieval-container {
      width: 840px;
      justify-content: center;
    }
    .right-retrieval-container {
      .upload-img {
        text-align: center;
      }
    }
    .picture-wall {
      width: 778px;
    }
    .picture-container {
      .pic-item:nth-child(5n) {
        margin-right: 0;
      }
      .pic-item {
        display: inline-block;
        width: 150px;
        height: 150px;
        font-size: 0;
        position: relative;
        background-position: 50%;
        background-size: 100%;
        margin-bottom: 7px;
        margin-right: 7px;
        border: 1px solid hsla(210, 2%, 51%, 0.2);
        background-origin: border-box;
        cursor: pointer;
        vertical-align: bottom;
        .img-info {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .active {
        border: 5px solid #61e6bd;
      }
      .upload-multiple {
        display: none;
      }
      .cross {
        margin: 0px auto;
        width: 0;
        height: 0;
        position: relative;
      }
      .cross:before,
      .cross:after {
        content: "";
        position: absolute;
        background: hsla(210, 2%, 51%, 0.2);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .cross:before {
        width: 100px;
        height: 5px;
      }
      .cross:after {
        width: 5px;
        height: 100px;
      }
    }
    .upload-img {
      margin-top: 60px;

      .location-img-uplaod {
        display: inline-block;
        width: 200px;
        height: 40px;
        background: #34b6fc;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        box-shadow: 0 5px 15px 0 rgba(68, 176, 252, 0.5);
        cursor: pointer;
      }
      .request-info {
        display: inline-block;
        min-width: 120px;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        font-size: 16px;
        color: #005887;
        text-align: left;
        background-color: #ebeff5;
        box-shadow: 0 2px 6px 0 rgba(47, 99, 150, 0.1);
      }
    }
  }
  .left-slider {
    -webkit-animation: animation-left-slider 0.5s linear;
            animation: animation-left-slider 0.5s linear;
  }
  .right-slider {
    -webkit-animation: animation-right-slider 0.5s linear;
            animation: animation-right-slider 0.5s linear;
  }
  @-webkit-keyframes animation-left-slider {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes animation-left-slider {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @-webkit-keyframes animation-right-slider {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes animation-right-slider {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  .face-contrast {
    position: relative;
    padding: 41px 0 99px;
    background-color: #f5f7fa;
    .experience-container-info {
      display: flex;
      position: relative;
      font-size: 0;
      background: transparent;
      width: 1300px;
      margin: 0 auto 20px;
      & > div {
        flex: 1 1;
      }
      .ml-title {
        position: relative;
        top: -50px;
        align-self: center;
        font-size: 60px;
        text-align: center;
        img {
          width: 100px;
        }
      }
    }
    .result-img-container {
      position: relative;
      text-align: center;
    }
    .img-result {
      height: 400px;
      padding: 10px;
      font-size: 16px;
      vertical-align: top;
      margin: auto;
      width: 400px;
      position: relative;
      box-shadow: 0 5px 15px 0 rgba(47, 99, 150, 0.1);
      text-align: center;
      background-color: #fff;
      background-position: 50%;
      cursor: pointer;
      .img-experience-block {
        position: relative;
        width: 350px;
        height: 380px;
        margin: 50% auto auto;
        transform: translateY(-50%);
        text-align: center;
        overflow: hidden;
        .upload-img-icon {
          margin-top: 30%;
        }
        .img-experience {
          width: 100%;
        }
        .upload-text {
          color: #bfbfbf;
        }

        .face-item {
          position: absolute;
          top: 0;
          z-index: 10;
          .face-line {
            position: absolute;
            opacity: 0.9;
            width: 25%;
            height: 25%;
          }
          .left-top {
            left: 0;
            top: 0;
            border-left: 3px solid #61e6bd;
            border-top: 3px solid #61e6bd;
          }
          .right-top {
            right: 0;
            top: 0;
            border-right: 3px solid #61e6bd;
            border-top: 3px solid #61e6bd;
          }
          .left-bottom {
            left: 0;
            bottom: 0;
            border-left: 3px solid #61e6bd;
            border-bottom: 3px solid #61e6bd;
          }
          .right-bottom {
            right: 0;
            bottom: 0;
            border-right: 3px solid #61e6bd;
            border-bottom: 3px solid #61e6bd;
          }
        }
        .face-items-info {
          position: absolute;
          z-index: 100;
          background-color: #61e6bd;
          padding: 14px 10px;
          .item-list {
            font-size: 14px;
            color: #000;
            line-height: 24px;
            text-align: left;
          }
        }
      }
    }
    .location-img-uplaod {
      margin: 30px auto;
      display: inline-block;
      width: 200px;
      height: 40px;
      background: #34b6fc;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
      box-shadow: 0 5px 15px 0 rgba(68, 176, 252, 0.5);
    }
    input {
      display: none;
    }
    .experience-result {
      width: 1300px;
      margin: 0px auto 0;
      margin-top: -145px;
      text-align: center;
      .img-analysis {
        position: relative;
        z-index: 10;
        display: inline-block;
        width: 100px;
        height: 40px;
        background: #34b6fc;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        box-shadow: 0 5px 15px 0 rgba(68, 176, 252, 0.5);
      }
      .request-info {
        display: inline-block;
        min-width: 200px;
        height: 40px;

        line-height: 40px;
        padding: 0 10px;
        font-size: 16px;
        color: #005887;
        text-align: left;
        background-color: #ebeff5;
        box-shadow: 0 2px 6px 0 rgba(47, 99, 150, 0.1);
      }
    }
  }
}
