.text {
    width: 1200px;
    margin: 24px auto;
    li {
        list-style:circle;
    }
.content {
    margin-bottom: 24px;
    text-indent: 12px;
}
}