.face-img {
  display: inline-block;

 
    .experience-container-info {
      display: flex;
      position: relative;
      font-size: 0;
      background: transparent;
      width: 1300px;
      margin: 0 auto 20px;
      & > div {
        flex: 1 1;
      }
      .ml-title {
        align-self: center;
        font-size: 60px;
        text-align: center;
      }
    }
    .result-img-container {
      position: relative;
    }
    .img-result {
      height: 400px;
      padding: 10px;
      font-size: 16px;
      vertical-align: top;
      width: 400px;
      margin-right: 20px;
      position: relative;
      box-shadow: 0 5px 15px 0 rgba(47, 99, 150, 0.1);
      background-color: #fff;
      background-position: 50%;
      .img-experience-block {
        position: relative;
        width: 350px;
        height: 380px;
        margin: 50% auto auto;
        transform: translateY(-50%);
        text-align: center;
        .img-img-icon {
          margin-top: 30%;
        }
        .img-block {
          height: 100%;
          width: 100%;
          overflow: hidden;
        }
        .img-experience {
          width: 100%;
        }
        .upload-text {
          color: #bfbfbf;
        }
        .face-item {
          position: absolute;
          top: 0;
          z-index: 10;
          .face-line {
            position: absolute;
            opacity: 0.9;
            width: 25%;
            height: 25%;
          }
          .left-top {
            left: 0;
            top: 0;
            border-left: 3px solid #61e6bd;
            border-top: 3px solid #61e6bd;
          }
          .right-top {
            right: 0;
            top: 0;
            border-right: 3px solid #61e6bd;
            border-top: 3px solid #61e6bd;
          }
          .left-bottom {
            left: 0;
            bottom: 0;
            border-left: 3px solid #61e6bd;
            border-bottom: 3px solid #61e6bd;
          }
          .right-bottom {
            right: 0;
            bottom: 0;
            border-right: 3px solid #61e6bd;
            border-bottom: 3px solid #61e6bd;
          }
        }
        .face-items-info {
          position: absolute;
          z-index: 100;
          background-color: #61e6bd;
          padding: 14px 10px;
          .item-list {
            font-size: 14px;
            color: #000;
            line-height: 24px;
            text-align: left;
          }
        }
      }
    }
    .location-img-uplaod {
      position: absolute;
      bottom: -60px;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      width: 200px;
      height: 40px;
      background: #34b6fc;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
      box-shadow: 0 5px 15px 0 rgba(68, 176, 252, 0.5);
    }
    input {
      display: none;
    }
}
