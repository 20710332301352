.solution {
  .nav-bg {
    position: relative;
    display: flex;
    height: 480px;
    width: 100%;
    background-size: 100% 100%;
  }
  .sv-tianyan {
    width: 1200px;
    margin: 58px auto;
    p:first-of-type {
      color: #2e2e2e;
      font-weight: bold;
      span {
        display: inline-block;
        padding-bottom: 10px;
        font-size: 25px;
        border-bottom: 2px solid #2e2e2e;
      }
    }
    p:nth-of-type(2) {
      color: #2e2e2e;
      font-size: 24px;
    }
  }
  .sv-app {
    padding: 20px;
    background: #f6f6f6;
    .title {
      text-align: center;
      color: #2e2e2e;
      font-size: 36px;
      font-family: Adobe Heiti Std;
      & > span {
        display: inline-block;
        padding: 10px 20px;
        border-bottom: 2px solid #2e2e2e;
      }
    }
    .swiper-container {
      width: 1000px;
      margin:auto;
      .swiper-slide {
        width: 500px;
        height: 600px;
        text-align: center;
        img {
          width: 100%;
          height: 254px;
          margin:auto;
        }
      }
    }
  }
  .sv-consumer {
    display: flex;
    width: 1200px;
    margin: 58px auto;
    &>div {
      flex: 1 1;
      height: 308px;
      width: calc(100%/3);
      margin: 0 20px;
      text-align: center;
      background: url(../../assets/sv-bt-bg.png);
      background-size: cover;
    }
    img {
      display: inline-block;
      margin: 20px auto;
      width: 97px;
      height: 97px;
      position: relative;
      z-index: 10;
    }
    p {
      padding: 0 40px;
      color: #fff;
    }
  }
}


