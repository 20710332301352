@import '../../style/variables.less';
.analysis-platform {
  .nav-bg {
    position: relative;
    height: 100vh;
    background-image: url(../../assets/ap-banner.png);
    background-size: cover;

    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      left: 20px;
      top: 20px;
      display: inline-block;
      width: 50px;
      height: 40px;
      background-image: url(../../assets/ap-nb-corner.png);
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .nb-title {
      margin-top: 80px;
      color: #fff;
      text-align: center;
      letter-spacing: 3px;
      line-height: 1.5;
      p:nth-of-type(1) {
        font-size: @font-size-md;
      }
      p:nth-of-type(2) {
        width: 1500px;
        font-size: 15px;
        margin: 20px auto 0;
      }
    }
    .nb-btn-wrapper {
      text-align: center;
      .nb-btn {
        display: inline-block;
        padding: 10px 50px;
        margin: 50px auto 140px;
        font-size: @font-size-base;
        color: #fff;
        border-radius: 25px;
        cursor: pointer;
        background-color: #9d40c5;
      }
      @media screen and (max-width: 1500px) {
        .nb-btn {
          margin: 40px auto;
        }
      }
    }
    .card-container {
      position: relative;
      width: 1200px;
      margin: auto;
    }
    .pc-card {
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin: auto;
      width: 900px;
      height: 500px;
      .pc-swiper {
        position: relative;
        top: 30px;
        width: 688px;
        height: 433px;
      }
      img {
        position: absolute;
        z-index: 1;
        width: 100%;
      }
    }
    .swiper-slide-img {
      display: flex;
      justify-content: center;
      img {
        height: 436px;
        width: auto;
        margin: auto;
      }
      background-color: #fff;
    }
    .slide-img-1 {
      img {
        position: relative;
        top: -25px;
        width: 100%;
        height: auto;
      }
    }
    .slide-img-3 {
      img {
        width: 100%;
        height: auto;
      }
    }

    .mobile-card {
      display: none;
      position: absolute;
      top: 20px;
      left: 900px;
      width: 230px;
      height: 428px;
      .mobile-swiper {
        position: absolute;
        top: 30px;
        left: 15px;
        width: 195px;
        height: 395px;
      }
      img {
        width: 100%;
      }
    }
  }
  .ap-model {
    width: 1400px;
    margin: 44px auto;
    .title {
      color: #2e2e2e;
      text-align: center;
      font-weight: bold;
      font-size: 35px;
      span {
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: 2px solid #2e2e2e;
      }
    }
    .ap-model-content {
      display: flex;
      li:nth-of-type(1) {
        background: #0070c0;
      }
      li:nth-of-type(2) {
        background: #ecb448;
      }
      li:nth-of-type(3) {
        background: #77b7d9;
      }
      li:nth-of-type(4) {
        background: #5fb7a2;
      }
      li {
        display: inline-block;
        text-align: center;
        color: #fff;
        padding: 20px;
        height: 350px;
        margin: 0 20px;
        width: 219px;
        img {
          width: 60px;
        }
        & > p:nth-of-type(1) {
          font-size: 25px;
        }
        & > p:nth-of-type(2) {
          line-height: 2;
        }
      }
    }
  }
}
