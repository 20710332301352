@import '../../style/variables.less';
.solution-brand {
  .nav-bg {
    position: relative;
    display: flex;
    height: 70vh;
    width: 100%;
    background-image: url(../../assets/brand-banner.png);
    background-size: 100% 100%;
  }
  .sm-challenge {
    position: relative;
    top: 15%;
    left: 30%;
    transform: translateX(-50%);
    width: 1200px;
    margin: auto 0;
    padding: 40px 0;
    p {
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 36px;
      line-height: 67px;
      color: #fff;
      text-align: center;
    }
  }
  .sm-value {
    width: 1200px;
    margin: auto;
    .sm-cv-ti {
      color: #2e2e2e;
      font-size: @font-size-lsm;
      line-height: 1;
    }
    .sm-cv-tx {
      color: #2e2e2e;
      font-size: 20px;
    }
    .sm-cv-img {
      text-align: center;
      img {
        display: inline-block;
        width: 55%;
      }
    }
  }
  .sm-tx-img {
    padding-top: 40px;
    background: #eeeeee;
    & > div {
      width: 1200px;
      margin: auto;
    }
      img {
      width: 400px;
      height: 250px;
    }
    .sb-img-1 {
      width: 500px;
    }
  }
  .sm-data {
    background: #fff;
  }
  .sb-strategy {
    width: 1200px;
    margin: auto;
    padding: 40px 0;
    &>p:first-of-type {
      margin-bottom: 20px;
      text-align: center;
      color: #2e2e2e;
      font-weight: bold;
      font-size: @font-size-lsm;
    }
    &>p:last-of-type {
      font-size: @font-size-base;
    }
  }
  .sb-app {
    width: 1200px;
    margin: auto;
    .sb-cl-ti {
      p {
        color: #2e2e2e;
        span {
          display: inline-block;
          padding-bottom: 10px;
          font-size: @font-size-lsm;
          font-weight: bold;
          border-bottom: 2px solid #2e2e2e;
        }
      }
    }
    .sb-cv-img {
      text-align: center;
      img {
        display: inline-block;
        width: 45%;
      }
    }
  }
}
