@import "../../style/variables.less";

ul,
li {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.home {
  .nav-bg {
    position: relative;
    height: 75vh;
    width: 100%;
    background: rgba(0, 0, 0);
    text-align: center;
    .alert-video-video {
      position: relative;
      height: 100%;
      object-fit: fill;
    }
    .nav-title {
      position: absolute;
      z-index: 10;
      top: 50.8%;
      width: 100%;
      white-space: nowrap;
      color: #fff;
      font-size: 55px;
      font-weight: normal;
      text-align: center;
    }
  }
  .hidden {
    display: none !important;
  }

  .home-study {
    width: 1200px;
    margin: 44px auto;
    & > .title {
      font-size: 44px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      text-align: center;
    }
    & > p:nth-of-type(2) {
      font-size: 22px;
    }
    & > p:nth-of-type(3) {
      font-size: 22px;
    }
  }
  .home-section-title {
    position: relative;
    z-index: 1;
    margin-top: 82px;
    margin-bottom: 63px;
    text-align: center;
    .title {
      position: relative;
      display: inline-block;
      width: 300px;
      padding-bottom: 13px;
      font-family: Adobe Heiti Std;
      border-bottom: 1px solid #dddddd;
      p:first-child {
        font-weight: bold;
        font-size: 29px;
        color: #000000;
      }
      p:last-child {
        margin-top: 3px;
        font-size: 14px;
        color: #727272;
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        margin: auto;
        width: 80px;
        height: 4px;
        background: #f8b600;
      }
    }
    .desc {
      margin-top: 40px;
      font-size: 20px;
      color: #727272;
    }
  }
  .home-ai {
    width: 1200px;
    margin: 0 auto 50px;
    line-height: 1;
    p {
      line-height: 1;
    }
    .ul-desc {
      width: 100%;
      height: 389px;
      box-sizing: border-box;
      overflow: hidden;
      li {
        position: relative;
        display: inline-block;
        height: 100%;
        width: 240px;
        color: #fff;
        vertical-align: top;
        white-space: nowrap;
        
        & > div {
          height: 293px;
        }
      }
      .li-block-active {
        width: 480px;
        color: #2c2c2c;
        box-sizing: border-box;
        transition: width 0.8s;
        background-color: #fff !important;
        overflow: hidden;
        .li-title {
          color: #8B8B8B;
        }
        
      }
      li:first-child.li-block-active {
        border-left: 1px solid #b2b2b2;
        border-top: 1px solid #b2b2b2;
        border-bottom: 1px solid #b2b2b2;
      }
      li:last-child.li-block-active {
        border-right: 1px solid #b2b2b2;
        border-top: 1px solid #b2b2b2;
        border-bottom: 1px solid #b2b2b2;
      }
      li:nth-child(2),
      li:nth-child(3).li-block-active {
        border-top: 1px solid #b2b2b2;
        border-bottom: 1px solid #b2b2b2;
      }
      li:nth-child(1) {
        background: #9be401;
      }
      li:nth-child(2) {
        background: #f8b600;
      }
      li:nth-child(3) {
        background: #5b9cd6;
      }
      li:nth-child(4) {
        background: #ed7d31;
      }
      .li-title {
        margin-top: 38px;
        margin-bottom: 39px;
        font-size: 18px;
        text-align: center;
        line-height: 1;
        box-sizing: border-box;
        text-decoration: none;
      }
      .li-left {
        position: relative;
        display: inline-block;
        width: 240px;
        text-align: center;
        white-space: normal;
        .ll-text-1 {
          font-size: 24px;
          a {
            text-decoration: none;
            color: inherit;
          }
        }
        .ll-img {
          margin-top: 30px;
          width: 88px;
          height: 88px;
        }
        .ll-text-2 {
          margin-top: 30px;
          font-size: 15px;
          line-height: 16px;
          font-weight: bold;
        }
      }
      .li-right {
        position: relative;
        display: inline-block;
        padding-left: 20px;
        vertical-align: top;
        box-sizing: border-box;
        white-space: normal;
        .lr-title {
          color: #4f4f4f;
          font-size: 18px;
        }
        .lr-list {
          margin-top: 18px;
          margin-left: 15px;
          width: 164px;
          & > p:first-child {
            position: relative;
            color: #2C2C2C;
            font-size: 14px;
            line-height: 1;
            &::before {
              content: "▶";
              position: absolute;
              left: -15px;
              color: #898989;
              font-size: 12px;
            }
          }
          & > p:last-child {
            margin-top: 8px;
            font-size: 12px;
            color: #676767;
            line-height: 16px;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 1px;
            height: 213px;
            width: 1px;
            background-color: #c5c5c5;
          }
        }
      }
      .li-btn {
        position: absolute;
        bottom: 21px;
        left: 0;
        right: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 101px;
        height: 32px;
        background: #f8b600;
        border-radius: 2px;
        color: #fff;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }

  .home-product {
    position: relative;
    .bg {
      position: absolute;
      left: 0;
      top: 200px;
      z-index: 0;
      width: 100%;
      height: 500px;
      background-image: url(../../assets/home-product-top-bg.png);
      background-size: 100% 100%;
    }
    .home-product-swiper {
      position: relative;
      z-index: 1;
      width: 1200px;
      margin: auto;
      .swiper-img {
        height: 500px;
        background: black;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .swiper-img1 {
        position: relative;

        background-size: c;
        .swiper-img1-text1 {
          position: absolute;
          top: 20px;
          left: 100px;
          & > p:first-child {
            font-size: 30px;
            color: #fff;
          }
        }
        .swiper-img1-text2 {
          position: absolute;
          bottom: 80px;
          right: 60px;
          color: #fff;
          font-size: @font-size-base;
          font-weight: bold;
         
          p {
            position: relative;
            left: 20px;
            margin-bottom: 40px;
            &::before {
              content: "●";
              margin-right: 5px;
              color: rgb(78, 174, 177);
            }
          }
          p:nth-child(1) {
            font-size: 36px;
            &::before {
             content: ''
            }
          }
        }
        .swiper-img1-card1 {
          position: absolute;
          bottom: 10px;
          left: 80px;
          & > img:first-child {
            width: 450px;
          }
          & > img:last-child {
            position: absolute;
            top: 23px;
            left: 21px;
            width: 409px;
            height: 231px;
          }
        }
        .swiper-img1-card2 {
          position: absolute;
          right: 0;
          top: 0;
          img {
            width: 300px;
          }
        }
      }
    }

    .pdt-list {
      width: 1200px;
      height: 600px;
      padding: 0;
      margin: 0 auto;
      .han-symbol {
        display: inline-block;
        background-image: url(../../assets/han-symbol.svg);
        background-size: 1260px 900px;
      }
      .da {
        width: 90px;
        height: 90px;
      }
      .num1 {
        background-position-y: 0;
      }
      .num2 {
        background-position-x: -90px;
      }
      .num3 {
        background-position-x: -180px;
      }
      .num4 {
        background-position-x: -270px;
      }
      .num5 {
        background-position-x: -360px;
      }
      .num6 {
        background-position-x: -450px;
      }
      .item-symbol {
        vertical-align: middle;
        margin-right: 12px;
        width: 20px;
        height: 20px;
        background-position-y: -163px;
      }
      .item-symbol-1 {
        background-position-x: -563px;
      }
      .item-symbol-2 {
        background-position-x: -588px;
      }
      .item-symbol-3 {
        background-position-x: -613px;
      }
      .item-symbol-4 {
        background-position-x: -638px;
      }
      .item-symbol-5 {
        background-position-x: -663px;
      }

      .pdt-item {
        float: left;
        height: 100%;
        width: 368px;
        padding: 0 15px;
        position: relative;
        border-width: 0 1px 0 0;
        border-style: solid;
        border-color: rgba(27, 43, 79, 0.1);
     
      
        background-size: 100% auto;
        background-repeat: no-repeat;
        box-sizing: border-box;
        .watermark {
          margin-top: 32px;
          margin-bottom: 24px;
        }
        .main-cont {
          height: 340px;
          position: absolute;
          bottom: 0;
          .wrap {
            width: 360px;
            height: 100%;
            position: relative;
            ul {
              padding: 0;
            }
            li {
              margin-bottom: 16px;
            }
            .item-symbol-text {
              line-height: 24px;
            }
            .brief {
              opacity: 0.8;
              font-size: 16px;
              color: #1b2b4f;
              line-height: 28px;
              padding-right: 24px;
              font-family: PingFangSC;
              position: absolute;
              bottom: 122px;
              
            }
          }
        }

        .ch-title {
          font-size: 26px;
          color: #1b2b4f;
          letter-spacing: 0;
          line-height: 40px;
          opacity: 0;
        }
        .en-title {
          opacity: 0.6;
          font-size: 14px;
          color: #1b2b4f;
          letter-spacing: -0.3px;
          line-height: 24px;
          font-weight: 700;
          padding-left: 3px;
        }
      }
      .pdt-item:nth-child(1) {
        background-image: url(../../assets/pdt-item-img-1.png);
      }
      .pdt-item:nth-child(2) {
        background-image: url(../../assets/pdt-item-img-2.png);
      }
      .pdt-item:nth-child(3) {
        background-image: url(../../assets/pdt-item-img-3.png);
      }
      .pdt-item-active {
        padding: 0 22px;
        background-image: none !important;
        width: 464px;
        transition: width 0.8s, padding-left 0.2s, padding-right 0.2s;
        box-sizing: border-box;
        .ch-title {
          opacity: 1;
        }
      }
    }
  }

  .home-partner {
    margin: auto;
    position: relative;
   

    & > .wallpaper-box {
      position: relative;
      top: 1;
      text-align: center;
      overflow: hidden;
      background: url("../../assets/product-wallpaper-bg.png");
      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .title {
        margin-top: 50px;
        margin-bottom: -60px;
        font-size: 47px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        text-align: center;
        color: #fff;
      }
      img {
        position: relative;
        z-index: 1;
        width: 1200px;
      }
      & > .wallpaper-more {
        position: relative;
        z-index: 1;
        padding-bottom: 20px;
        display: block;
        text-align: center;
        .link {
          position: relative;
          top: -30px;
          padding: 15px 80px;
          font-size: 20px;
          color: #fff;
          cursor: pointer;
          border: 1px solid #fff;
          text-decoration: none;
          &:hover {
            border-color: @primary-color;
            box-shadow: 1px 1px 10px @primary-color;
          }
        }
      }
    }
  }
}

.wallpaper-box-wrap {
  position: relative;
  display: inline-block;
  width: 104px;
  height: 60px;
  background-color: #fff;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -50%;
    width: 0;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
    border-bottom: 30px solid #fff;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -50%;
    width: 0;
    border-top: 30px solid #fff;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
  }
  &:hover {
    background: red;
    transform: translateX(180deg);
    transition: transform 1s linear;
  }
}
